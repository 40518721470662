import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {ToastComponent, ZenToastDataModel} from "../../_standalone-components/toast/toast.component";

@Injectable({
    providedIn: 'root'
})
export class ZenDialogMsgService {

    constructor(private snackBar: MatSnackBar) {
    }

    openToast(success: boolean, msg?: string, duration?: number) {
        const cls = success ? 'success-toast' : 'warn-toast';
        this.snackBar.openFromComponent(ToastComponent, {
            duration: duration || 6000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: [cls],
            data: {
                message: msg || (success ? 'Success' : 'Error'),
                icon: success ? ZenIconsEnum.DONE : ZenIconsEnum.ERROR
            } as ZenToastDataModel
        });
    }

}
